$spacing = 2rem

hanzo-order
  gmap
    height: 360px

hanzo-orders
  .header
    h2
      float: left
    button
      float: right
      margin-left: $spacing
      width: auto

      &.open
        opacity: .5

    &.img
      max-width: 100px
      padding: 10px
