$spacing = .5rem

hanzo-home
  daisho-graphics-chart
    display: table

    svg
      padding: 0
      margin: 0

  .header
    & > *
      display: table
      width: 100%
      padding: $spacing * 1.25 $spacing * 2.5

    h2
      line-height: 1.4em
      float: left

  date-range-picker-control
    display: table
    float: right

  .charts
    margin: 0 0 $spacing * 4

    & > *
      width: 100%

  .counters
    margin: 0 $spacing * -2
    align-items: flex-start

    & > *
      margin: 0 $spacing * 2


