$spacing = 2rem

hanzo-subscribers
  .header
    h2
      float: left
    button
      float: right
      margin-left: $spacing
      width: auto

      &.open
        opacity: .5
