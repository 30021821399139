$spacing = 2rem

hanzo-integrations
  .header
    h2
      float: left
    button
      float: right
      margin-left: $spacing
      width: auto

      &.open
        opacity: .5

    &.img
      max-width: 100px
      padding: 10px
