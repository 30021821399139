$spacing = 2rem

hanzo-product
  .dimensions
    .input
      position: relative
      &:nth-child(-n+2):after
        content: 'x'
        display: block
        position: absolute
        right: -.9em
        bottom: -.3em

  .uploaded-images
    justify-content: flex-start

    .uploaded-image
      flex: 0

      img
        width: 150px
        max-width: initial

hanzo-products
  .header
    h2
      float: left
    button
      float: right
      margin-left: $spacing
      width: auto

      &.open
        opacity: .5

    &.img
      max-width: 100px
      padding: 10px
